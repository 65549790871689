/* Extern */
import React from 'react';
import { inject, observer } from 'mobx-react';

import { Icon, Item, Label } from 'semantic-ui-react';

import Avatar from '../components/Avatar';
import Popup from '../components/Popup';

/* Intern */
import { sendGoogleAnalyticsEvent } from '../lib/analytics';
import { hasNetwork } from '../app/helpers/hasNetwork';
import { __ } from '../i18n';

@inject('store')
@observer
export default class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }

  compressSideMenu(menuItem) {
    const { store, router } = this.props;
    const { expanded } = this.state;

    if (router?.location?.pathname.includes(menuItem) && expanded) {
      store.isMenuExpanded = false;
      this.setState({ expanded: false });
    }
  }

  async componentDidMount() {
    const { store } = this.props;
    const { isMenuExpanded } = store;

    if (!isMenuExpanded) {
      this.setState({ expanded: isMenuExpanded });
    }

    this.compressSideMenu('chats');
  }

  __handleOnClick = (menuItem, isActive) => {
    const { router, store } = this.props;
    const pathname = router && router.location && router.location.pathname;
    const isOrganizationSideMenu = /organization/g.test(pathname);
    const isEntitySideMenu = /entities/g.test(pathname);
    const organizationId =
      store.currentOrganization && store.currentOrganization.id;
    const entityType = store.currentEntity && store.currentEntity.type;
    const menuItemUpperCase =
      menuItem && menuItem.id && menuItem.id.toUpperCase();

    if (menuItem.openPaywall) {
      if (isOrganizationSideMenu) {
        sendGoogleAnalyticsEvent(
          {
            name: 'Sidebar',
            category: `Sidebar click by ${entityType} - ${menuItemUpperCase} - Paywall`,
            label: `OrganizationId: ${organizationId}`,
          },
          { store },
        );
      }
      menuItem.openPaywall();
    } else if (!isActive) {
      if (isOrganizationSideMenu) {
        sendGoogleAnalyticsEvent(
          {
            name: 'Sidebar',
            category: `Sidebar click by ${entityType} - ${menuItemUpperCase}`,
            label: `OrganizationId: ${organizationId}`,
          },
          { store },
        );
      }

      if (menuItem.name === __('Chats') && isEntitySideMenu) {
        const entity = store.entity ?? store.currentEntity;
        sendGoogleAnalyticsEvent(
          {
            name: 'Sidebar - Chats',
            category: `Sidebar click by ${entity?.fullname} - ${entityType}`,
            label: `EntityId: ${entity?.id}`,
          },
          { store },
        );
      }

      router.push(menuItem.to);
    }
  };

  _renderItem = (menuItem) => {
    const { expanded } = this.state;
    const { store } = this.props;
    const pathname = store.currentLocation.pathname;

    let isActive = menuItem.to && pathname.includes(menuItem.to);
    if (
      menuItem.to &&
      menuItem.to.includes('dashboard') &&
      pathname.includes('dashboard')
    ) {
      isActive = true;
    }

    if (store.currentOrganization) {
      if (
        (pathname === `/organizations/${store.currentOrganization.id}/` ||
          pathname === `/organizations/${store.currentOrganization.id}`) &&
        menuItem.to.includes('entities')
      ) {
        isActive = true;
      }
      if (
        (pathname.includes(
          `/organizations/${store.currentOrganization.id}/accounts`,
        ) ||
          pathname.includes(
            `/organizations/${store.currentOrganization.id}/charges`,
          ) ||
          pathname.includes(
            `/organizations/${store.currentOrganization.id}/paymentTerms`,
          )) &&
        menuItem.to.includes('payments')
      ) {
        isActive = true;
      }
      if (
        pathname.includes(
          `/organizations/${store.currentOrganization.id}/arrived`,
        ) &&
        menuItem.to.includes('arrived')
      ) {
        isActive = true;
      }
      if (
        pathname.includes('/communication') &&
        menuItem.to.includes('messages')
      ) {
        isActive = true;
      }
    }

    if (store.currentEntity) {
      if (
        (pathname === `/entities/${store.currentEntity.id}/` ||
          pathname === `/entities/${store.currentEntity.id}`) &&
        menuItem.to.includes('messages')
      ) {
        isActive = true;
      }
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        onClick={() => {
          if (menuItem.to.includes('chats')) {
            store.isMenuExpanded = false;
            this.setState({ expanded: false });
          } else {
            store.isMenuExpanded = true;
            this.setState({ expanded: true });
          }
          this.__handleOnClick(menuItem, isActive);
        }}
        key={`side-menu-item-${menuItem.to}`}
        id={`side-menu-item-${menuItem.to}`}
        data-action={menuItem['data-action']}
        data-testid={`menuItem-${menuItem.name}`}
      >
        <div style={{ display: 'flex', color: 'inherit' }}>
          <div className={`side-menu-item ${isActive ? 'active' : ''}`}>
            <div className="wrapper">
              <Icon
                disabled={menuItem.openPaywall && !expanded}
                className={`${menuItem.icon} ${isActive ? 'duotone' : ''}`}
              />

              <span
                data-testid="side-menu-item-name"
                hidden={!menuItem.name || !expanded}
                className={isActive ? 'active' : ''}
              >
                {menuItem.name}
              </span>
            </div>
            {menuItem.name && !expanded && (
              <p className="description">{menuItem.name}</p>
            )}
            {menuItem.rightIcon && expanded && (
              <Icon
                name={menuItem.rightIcon}
                style={{ margin: '0px 0px 0px 8px', color: '#A5A5A5' }}
              />
            )}
            {expanded && menuItem.quantity > 0 && (
              <div>
                <Label
                  circular
                  size="small"
                  color="red"
                  key={'label_' + menuItem.name}
                  style={{ margin: '0px 0px 0px 8px' }}
                >
                  {menuItem.quantity}
                </Label>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  sideMenuWriteButton = () => {
    const { store, router } = this.props;

    if (router.location.pathname.includes('chats')) {
      store.isMenuExpanded = true;
      this.setState({ expanded: true });
    }

    if (hasNetwork(store.currentOrganization)) {
      router.push(
        `/entities/${store.currentEntity && store.currentEntity.id}/communications/create`,
      );
      return;
    }

    router.push(
      `/entities/${store.currentEntity && store.currentEntity.id}/messages/add`,
    );
  };

  render() {
    const { expanded } = this.state;
    const { store, headerButton, items, router } = this.props;

    const isNetworkPathname =
      router?.location?.pathname?.includes('communications') ?? false;

    const shouldShowWriteButton =
      headerButton && headerButton === 'write' && !isNetworkPathname;

    const existingUser = true;
    return (
      <div
        className={`side-menu ${expanded && existingUser ? 'expanded' : ''}`}
      >
        <div
          className={`inner-side-menu ${expanded && existingUser ? 'expanded' : ''}`}
        >
          {headerButton && headerButton === 'config' && (
            <div data-action={'profileButton'} style={{ marginBottom: '3rem' }}>
              <div style={{ display: 'flex', color: 'inherit' }}>
                <div className={'side-menu-item noHover'}>
                  <div className="wrapper">
                    <Icon name="cog" />
                    <span hidden={!expanded}>{__('Settings')}</span>
                  </div>
                  {!expanded && <p className="description">{__('Settings')}</p>}
                </div>
              </div>
            </div>
          )}
          {shouldShowWriteButton && (
            <div
              onClick={() => this.sideMenuWriteButton()}
              data-action={'writeButton'}
              style={{ marginBottom: '3rem' }}
            >
              <div style={{ display: 'flex', color: 'inherit' }}>
                <div className={'side-menu-item colorful'}>
                  <div className="wrapper">
                    <Icon
                      className={'plus'}
                      style={{ color: '#FFF', margin: 0 }}
                    />

                    <span
                      hidden={!expanded}
                      style={{ color: '#FFF', marginRight: '0.5rem' }}
                      data-testid="side-writeButton"
                    >
                      {__('Write')}
                    </span>
                  </div>
                  {!expanded && <p className="description">{__('Write')}</p>}
                </div>
              </div>
            </div>
          )}
          {headerButton && headerButton === 'profile' && (
            <div data-action={'profileButton'} style={{ marginBottom: '3rem' }}>
              <div style={{ display: 'flex', color: 'inherit' }}>
                <div className={'side-menu-item noHover'}>
                  <div className="wrapper">
                    <Avatar
                      avatar
                      src={
                        store.entity &&
                        store.entity.picture &&
                        store.entity.picture.uri
                      }
                      alt={store.entity && store.entity.fullname}
                    />
                    <span hidden={!expanded}>
                      {store.entity && store.entity.fullname.length > 12
                        ? store.entity &&
                          store.entity.fullname.substring(0, 11) + '...'
                        : store.entity && store.entity.fullname}
                    </span>
                  </div>
                  {!expanded && (
                    <p className="description">
                      {store.entity && store.entity.fullname}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
          {existingUser &&
            items?.length > 0 &&
            items
              .filter((item) => !!item)
              .map((item) => {
                if (/files/.test(item.to)) {
                  return (
                    <Popup
                      name="FilesPopup"
                      title={__('Add file folders to ClassApp! 🗂️')}
                      text={__(
                        'Now you can make files available to students and their families and organize them in folders! 😍',
                      )}
                      position="right center"
                      showOnStart
                    >
                      {this._renderItem(item)}
                    </Popup>
                  );
                }
                return this._renderItem(item);
              })}
          {existingUser && items?.length > 0 && (
            <Item
              data-testid="expand-or-minimize-menu"
              className="side-menu-item trigger"
              onClick={() => {
                store.isMenuExpanded = !expanded;
                this.setState({ expanded: !expanded });
              }}
            >
              <div className="item-icon">
                <Icon className={expanded ? 'angle left' : 'angle right'} />
              </div>
              <p className="description">
                {expanded ? __('Minimize menu') : __('Expand menu')}
              </p>
            </Item>
          )}
        </div>
      </div>
    );
  }
}
