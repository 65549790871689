import React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import { Icon } from 'semantic-ui-react';

import { hasNetwork } from '../app/helpers/hasNetwork';

const styles = {
  button: {
    backgroundColor: '#084FFF',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 85,
    right: 25,
    width: '64px',
    height: '64px',
    zIndex: 999,
    borderRadius: '50%',
  },
  icon: {
    fontWeight: 300,
    fontSize: '1.5rem',
    color: '#FFFFFF',
    marginLeft: '2px',
  },
};

@inject('store')
@observer
export default class ActionButton extends React.Component {
  constructor(params) {
    super(params);
    this.state = { urlError: false };
  }

  handleOnClick = () => {
    const { store } = this.props;
    const currentEntity = store && store.currentEntity;

    const __hasNetwork = hasNetwork(store.currentOrganization);

    if (__hasNetwork) {
      browserHistory.push(
        `/entities/${currentEntity.id}/communications/create`,
      );
      return;
    }

    browserHistory.push(`/entities/${currentEntity.id}/messages/add`);
  };

  render() {
    return (
      <div style={styles.button} onClick={() => this.handleOnClick()}>
        <Icon className={'plus'} style={styles.icon} />
      </div>
    );
  }
}
