// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';

/**
 * @typedef {{
 *  env: 'local' | 'stage' | 'internal' | 'production';
 *  user: {
 *   name: string;
 *   email: string;
 *  };
 *  plan: string;
 * }} Props
 * @extends {React.Component<Props>}
 */
export function ZendeskWidget({ env = 'local', user, plan }) {
  useEffect(() => {
    const isProductionEnvironment = ['stage', 'internal'].includes(env);

    const alreadyLoaded = document.getElementById('ze-snippet');
    const isNotFreePlan = plan && plan !== 'FREE';

    const hasScript = !alreadyLoaded && user && isNotFreePlan;

    if (!hasScript || !isProductionEnvironment) {
      return;
    }

    const script = document.createElement('script');
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=d1408c62-252d-4b18-9ea8-ba1aaf5e21a6';
    script.async = true;
    script.id = 'ze-snippet';

    script.onload = () => {
      // eslint-disable-next-line no-undef
      zE('webWidget', 'identify', {
        name: user?.name,
        email: user?.email,
      });
    };

    document.head.appendChild(script);
  }, [env, plan, user]);

  return null;
}
